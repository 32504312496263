<template>
  <CentralizeLayout>
    <div class="bg-1"></div>
    <div class="bg-2"></div>
    <b-col md="4">
      <div class="text-center mb-4">
        <logo name="logo" />
      </div>
      <b-alert
        class="mt-3"
        :show="hasAuthenticationStatus"
        :variant="'danger'"
        >{{ $t(`error.${errorCode}`) }}</b-alert
      >
      <b-card class="p-2 auth-card">
        <h5 class="mb-4">{{ $t("createNewPassword") }}</h5>
        <b-form class="mt-3" novalidate @submit.prevent="onSubmit">
          <b-form-group :label="$t('email')">
            <b-form-input
              v-model.trim.lazy="$v.form.email.$model"
              type="email"
              required
              disabled
              :state="validateState('email')"
              @blur="$v.form.email.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("emailErrMsg")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group :label="$t('confirmationCode')">
            <b-form-input
              v-model.trim.lazy="form.code"
              type="text"
              required
              maxlength="6"
              :state="validateState('code')"
              :disabled="isLoading"
              @blur="$v.form.code.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("codeErrMsg")
            }}</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group
            :label="$t('newPassword')"
            class="mt-3 position-relative"
          >
            <b-form-input
              v-model.trim.lazy="$v.form.password.$model"
              :type="showPassword ? 'text' : 'password'"
              required
              maxlength="12"
              :state="validateState('password')"
              :disabled="isLoading"
              @blur="$v.form.password.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("passwordErrMsg")
            }}</b-form-invalid-feedback>
            <b-icon
              :icon="showPassword ? 'eye' : 'eye-slash'"
              @click="showPassword = !showPassword"
              class="input-icon"
              v-if="!$v.form.password.$invalid"
            ></b-icon>
          </b-form-group>
          <b-form-group
            :label="$t('reenterPassword')"
            class="mt-3 position-relative"
          >
            <b-form-input
              v-model.trim.lazy="$v.form.reenterPassword.$model"
              :type="showPassword ? 'text' : 'password'"
              required
              maxlength="12"
              :state="validateState('password')"
              :disabled="isLoading"
              @blur="$v.form.password.$touch()"
            />
            <b-form-invalid-feedback
              :state="$v.form.reenterPassword.sameAsPassword"
              >{{ $t("reenterPasswordErrMsg") }}</b-form-invalid-feedback
            >
            <b-icon
              :icon="showPassword ? 'eye' : 'eye-slash'"
              @click="showPassword = !showPassword"
              class="input-icon"
              v-if="!$v.form.password.$invalid"
            ></b-icon>
          </b-form-group>
          <b-button
            class="mt-4"
            block
            :disabled="isLoading"
            type="submit"
            variant="primary"
          >
            <b-icon-arrow-clockwise v-show="isLoading" animation="spin" />
            {{ $t("createNewPassword") }}
          </b-button>
        </b-form>
        <div class="mt-2 d-flex justify-content-center">
          <b-button
            variant="link"
            :to="{ name: 'Login', query: { lang: this.$route.query.lang } }"
          >
            {{ $t("returnLogin") }}
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col lg="4" class="text-footer">
      <div>
        <span class="mr-2">{{ $t("contact") }}</span>
        <span class="mr-2">{{ $t("legal") }}</span>
        <span>{{ $t("privacy") }}</span>
      </div>

      <div class="ml-auto">
        <b-dropdown class="language-dropdown" dropup no-caret right>
          <template #button-content>
            <div class="d-flex flex-row align-items-center">
              <icon class="mr-2" name="language" />
              <span v-if="$root.$i18n.locale === 'en'" style="font-size: 12px;"
                >English</span
              >
              <span v-if="$root.$i18n.locale === 'id'" style="font-size: 12px;"
                >Indonesia</span
              >
              <b-icon
                class="caret ml-1"
                icon="caret-up-fill"
                font-scale="0.85"
              />
            </div>
          </template>
          <b-dropdown-item @click="setLanguageEn">English</b-dropdown-item>
          <b-dropdown-item @click="setLanguageId"
            >Bahasa Indonesia</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </b-col>
  </CentralizeLayout>
</template>

<i18n>
{
  "en": {
    "email": "Email",
    "emailErrMsg": "Enter your email address.",
    "codeErrMsg": "Enter the confirmation code.",
    "createNewPassword": "Create new password",
    "email": "Email",
    "emailErrMsg": "Choose a valid email address.",
    "confirmationCode": "Confirmation code",
    "newPassword": "New password",
    "reenterPassword": "Re-enter password",
    "passwordErrMsg": "Choose a password between 8-12 characters, with at least 1 uppercase, 1 lowercase, 1 number and 1 special character.",
    "reenterPasswordErrMsg": "Password didn't match.",
    "passwordPlaceholder": "At least 8 character",
    "returnLogin": "Return to log in",
    "error": {
      "UsernameExistsException": "An account with the given email already exists.",
      "unspecific": "Invalid confirmation code."
    },
    "contact": "Contact",
    "legal": "Legal",
    "privacy": "Privacy & terms",
    "languageSaved": "Language is saved"
  },
  "id": {
    "email": "Email",
    "emailErrMsg": "Masukkan alamat email anda.",
    "codeErrMsg": "Masukkan kode konfirmasi.",
    "createNewPassword": "Buat password baru",
    "email": "Email",
    "emailErrMsg": "Pilih alamat email yang valid.",
    "confirmationCode": "Kode konfirmasi",
    "newPassword": "Kata sandi baru",
    "reenterPassword": "Masukkan ulang kata sandi",
    "passwordErrMsg": "Pilih kata sandi antara 8-12 karakter, dengan minimal 1 huruf besar, 1 huruf kecil, 1 angka dan 1 karakter khusus",
    "reenterPasswordErrMsg": "Kata sandi tidak sama.",
    "passwordPlaceholder": "Setidaknya 8 karakter",
    "returnLogin": "Kembali untuk masuk",
    "error": {
      "UsernameExistsException": "Akun dengan email yang diberikan sudah ada.",
      "unspecific": "Kode konfirmasi tidak valid."
    },
    "contact": "Kontak",
    "legal": "Hukum",
    "privacy": "Privasi & persyaratan",
    "languageSaved": "Bahasa disimpan"
  }
}
</i18n>

<script>
import {
  required,
  sameAs,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import CentralizeLayout from "@/layouts/CentralizeLayout";
import { mapGetters, mapActions } from "vuex";

const errorCodes = ["UsernameExistsException"];
const fallbackErrorCode = "unspecific";

export default {
  components: {
    CentralizeLayout
  },
  data() {
    return {
      isLoading: false,
      form: {
        email: "",
        code: "",
        password: "",
        reenterPassword: ""
      },
      showPassword: false
    };
  },
  computed: {
    ...mapGetters("auth", ["hasAuthenticationStatus", "authenticationStatus"]),
    errorCode() {
      return errorCodes.includes(this.authenticationStatus.code)
        ? this.authenticationStatus.code
        : fallbackErrorCode;
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      code: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      password: {
        required,
        password: v =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,12}$/.test(v)
      },
      reenterPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  mounted() {
    this.clearAuthenticationStatus();
    this.$v.form.email.$model = this.$route.params.email;

    if (this.lang === "en") {
      this.$root.$i18n.locale = "en";
    } else {
      this.$root.$i18n.locale = "id";
    }
  },
  methods: {
    ...mapActions("auth", [
      "clearAuthenticationStatus",
      "confirmPasswordReset"
    ]),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error ? false : null;
    },
    setLanguageEn() {
      this.$router.replace({ query: { lang: "en" } });
    },
    setLanguageId() {
      this.$router.replace({ query: { lang: "id" } });
    },
    async onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      await this.confirmPasswordReset({
        username: this.form.email,
        code: this.form.code,
        password: this.form.password
      });
      if (!this.hasAuthenticationStatus) {
        this.$router.push({
          name: "Login"
        });
      }
      this.isLoading = false;
    }
  },
  watch: {
    $route() {
      if (this.$route.query.lang === "id") {
        this.$root.$i18n.locale = "id";
      } else {
        this.$root.$i18n.locale = "en";
      }
    }
  }
};
</script>
